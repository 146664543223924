<template>
  <div class="commonBox">
    <sideBar :sideBarList="serviceList" @changeMenu="changeMenu" ref="sideBar" />
    <div class="commonMainArea">
      <div class="digitalCertificate clearfix" v-if="showContent===0">
        <h2>数字证书（Ukey证书）</h2>
        <dl class="icon-animation-box" v-for="(item,index) in serves" :key="index" @click="goRouter(item.name)">
          <dt class="icon-animation icon" :style="{'background-image': `url(${require('@/assets/icons/home/serve-icon-'+(item.name)+'.png')})`}"></dt>
          <dd>{{item.label}}</dd>
        </dl>
      </div>
      <div class="businessList" v-if="showContent===1">
        <businessList ref="businessOrder" />
      </div>
      <div class="cloudCertBox" v-if="showContent===2">
        <cloudCert ref="cloudCert" />
      </div>
      <div class="orderGoods" v-if="showContent===3">
        <orderList ref="orders" />
      </div>
      <div class="reportBox" v-if="showContent===4">
        <reportList ref="report" />
      </div>
    </div>
    <orderDetails ref="orderDetails" />
    <reportDetails ref="reportDetails" />
    <cloudCertDetails ref="cloudCertDetails" />
  </div>
</template>

<script>
import orderList from './orderList.vue';
import cloudCert from './cloudCert.vue';
import reportList from './reportList.vue';
import orderDetails from './orderDetails.vue';
import reportDetails from './reportDetails.vue';
import sideBar from './../components/sideBar.vue'
import cloudCertDetails from './cloudCertDetails.vue';
import { serviceCenter, serves } from '@/utils/options'
import businessList from './../components/businessList.vue';
export default {
  data () {
    return {
      serves: serves,
      serviceList: serviceCenter,
      showContent: 0
    }
  },
  components: {
    sideBar,
    orderDetails,
    businessList,
    orderList,
    reportList,
    reportDetails,
    cloudCert,
    cloudCertDetails
  },
  mounted () {
    let index = Number(this.$route.query.type)
    this.$refs.sideBar.currentIndex = index || 0
    if (index === 1 || index === 2 || index === 3 || index === 4) {
      this.changeMenu(index)
    }
  },
  methods: {
    verify (name) {
      this.$bus.$off('afterLogin')
      this.$bus.$on('afterLogin', () => {
        this.verify(name)
      })
      this.$api.getAccountInfo().then(res => {
        if (res.data.verified) {
          this.$router.push({ name })
        } else {
          this.$confirm('当前账号未完成管理员实名认证，实名之后才可办理业务。', '提示', {
            confirmButtonText: '实名认证',
            cancelButtonText: '暂不认证',
            type: 'warning'
          }).then(() => {
            this.$router.push({ name: 'personHome' })
          }).catch(() => {

          });
        }
      })
    },
    goRouter (name) {
      if (['certQuery', 'fileVerify'].includes(name)) {
        this.$router.push({ name })
      } else if (['updata'].includes(name)) {
        this.$router.push({ name: 'downloadList', query: { type: '1' } })
      } else {
        this.verify(name)
      }
    },
    changeMenu (index) {
      this.showContent = index
      if (index === 1) {
        this.$nextTick(() => {
          this.$refs.businessOrder.getDataList()
        })
      } else if (index === 2) {
        this.$nextTick(() => {
          this.$refs.cloudCert.getCloud()
        })
      } else if (index === 3) {
        this.$nextTick(() => {
          this.$refs.orders.getOrders()
        })
      } else if (index === 4) {
        this.$nextTick(() => {
          this.$refs.report.getReportList()
        })
      }
    },
    openOrderDetails (orderNo) {
      this.$refs.orderDetails.orderNo = orderNo
      this.$refs.orderDetails.getOrderDetails()
    },
    openReportDetails (transactionId) {
      this.$refs.reportDetails.transactionId = transactionId
      this.$refs.reportDetails.getReportDetails()
    },
    refreshData (transactionType) {
      transactionType === 0 ? this.$refs.report.activeName = 'electron' : this.$refs.report.activeName = 'paper'
      this.$refs.report.transactionType = transactionType
      this.$refs.report.getReportList()
    },
    openCloudDetails (orderNo) {
      this.$refs.cloudCertDetails.orderNo = orderNo
      this.$refs.cloudCertDetails.getCloudDetails()
    },
    refreshOrder () {
      this.$refs.orders.getOrders()
    },
    refreshCloud () {
      this.$refs.cloudCert.getCloud()
    }
  }
}
</script>

<style lang="scss" scoped>
.digitalCertificate {
  padding: 40px;
  h2 {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    padding-bottom: 24px;
    font-weight: normal;
  }
  dl {
    width: 220px;
    height: 164px;
    background: #f7faff;
    border-radius: 2px;
    border: 1px solid #dee0e9;
    margin-right: 24px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    float: left;
    cursor: pointer;
  }
  dt {
    cursor: pointer;
  }
  dd {
    font-size: 16px;
    color: #303b50;
    line-height: 22px;
    margin-top: 8px;
    cursor: pointer;
  }
}
.businessList,
.orderGoods {
  padding: 41px 32px 72px;
}
.reportBox,
.cloudCertBox {
  padding: 32px 32px 72px;
}
</style>