<template>
  <div class="list-pagination">
    <span class="pageNumber">共 {{sum}} 条记录 第 {{currentPage}} / {{Math.ceil(sum/size)}} 页</span>
    <el-pagination class="systemPagination" @size-change="pagesChange" @current-change="currentChange" :current-page="currentPage" :page-sizes="[10,30,50 ]" :page-size="size"
      layout="sizes, prev, pager, next, jumper" :total="sum">
    </el-pagination>
  </div>
</template>
<script>
export default {
  methods: {
    pagesChange (val) {
      // this.size = val
      // this.currentPage = 1
      this.$emit('page', val)
    },
    currentChange (val) {
      // this.currentPage = val
      this.$emit('current', val)
    }
  },
  data () {
    return {}
  },
  props: {
    sum: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number
    },
    size: {
      type: Number,
      default: 50
    }
  }
}
</script>
<style lang="scss">
.list-table + .list-pagination {
  padding: 10px;
  border: 1px solid #e8ecf2;
  border-top: 0 none;
}
.list-pagination {
  display: flex;
  justify-content: space-between;
  .pageNumber {
    font-size: 12px;
    color: #686f7e;
    line-height: 30px;
  }

  .el-pagination {
    padding: 0;
    position: relative;
    .btn-next,
    .btn-prev {
      padding: 0;
      min-width: 28px;
      height: 28px;
      border: 1px solid #dee2e8;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      .el-icon {
        padding: 0;
        margin: 0;
        font-size: 12px;
        cursor: pointer;
      }
      &:disabled {
        cursor: not-allowed;
        .el-icon {
          cursor: not-allowed;
        }
      }
    }
    .btn-next {
      margin-right: 94px;
    }
    .el-pagination__sizes {
      position: absolute;
      top: 0;
      right: 88px;
    }
    .el-pager li {
      min-width: 28px;
      height: 28px;
      line-height: 28px;
      font-weight: 400;
      margin-left: 8px;
      font-size: 12px;
      color: #686f7e;
      border: 1px solid #dee2e8;
      border-radius: 4px;
      &.active {
        background-color: #4873f6;
        color: #fff;
        cursor: default;
      }
      &:last-child {
        margin-right: 8px;
      }
      &.active + li {
        border-left: 1px solid #dee2e8;
      }
    }
    .el-pagination__jump {
      .el-pagination__editor.el-input .el-input__inner {
        height: 28px;
        width: 28px;
      }
    }
  }
}
</style>
