<template>
  <div class="cloudCert" v-loading="cloudLoad">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="云证书API集成服务（实名+证书申请）" name="api">
        <div class="signatureList">
          <el-table ref="tableList1" :data="cloudlist" style="width: 100%;" header-row-class-name="tableHeadTitle" @filter-change="filterTag" :filter-multiple='false'>
            <el-table-column prop="orderNo" label="业务单号" min-width="180"></el-table-column>
            <el-table-column prop="orderTime" label="申请时间" min-width="160"></el-table-column>
            <el-table-column prop="companyName" label="申请主体" show-overflow-tooltip min-width="200"></el-table-column>
            <el-table-column prop="productSpecs" label="规格" min-width="80">
              <template slot-scope="scope">
                <span>{{scope.row.productSpecs===0?'纯API版':'网页版'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="业务单状态" column-key="orderStatus" :filters="cloudStatus" :filter-multiple='false' min-width="170">
              <template slot-scope="scope">
                <span :class="'statusColor statusColor'+scope.row.status">{{scope.row.status|orderStatus}}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" prop="" label="操作" width="180">
              <template slot-scope="scope">
                <el-button type="text" v-if="scope.row.status===2" @click="goPayment(scope.row.orderNo)">立即付款</el-button>
                <span v-else></span>
                <el-button @click="cancelCloudCert(scope.row.orderNo)" type="text" v-if="scope.row.status<=2">取消</el-button>
                <span v-else></span>
                <el-button @click="getCloudInfo(scope.row.orderNo)" type="text">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pager-list">
            <v-pagination :size="pageSize" :sum="Number(total)" @page="pageChange" @current="currentChange" :currentPage="pageIndex" />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="云证书API集成服务（证书申请）" name="page">
        <div class="signatureList">
          <el-table ref="tableList2" :data="cloudlist" style="width: 100%;" header-row-class-name="tableHeadTitle" @filter-change="filterTag" :filter-multiple='false'>
            <el-table-column prop="orderNo" label="业务单号" min-width="180"></el-table-column>
            <el-table-column prop="orderTime" label="申请时间" min-width="160"></el-table-column>
            <el-table-column prop="companyName" label="申请主体" show-overflow-tooltip min-width="200"></el-table-column>
            <el-table-column prop="productSpecs" label="规格" min-width="80">
              <template slot-scope="scope">
                <span>{{scope.row.productSpecs===0?'纯API版':'网页版'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="业务单状态" column-key="orderStatus" :filters="cloudStatus" :filter-multiple='false' min-width="170">
              <template slot-scope="scope">
                <span :class="'statusColor statusColor'+scope.row.status">{{scope.row.status|orderStatus}}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" prop="" label="操作" min-width="180">
              <template slot-scope="scope">
                <el-button type="text" v-if="scope.row.status===2" @click="goPayment(scope.row.orderNo)">立即付款</el-button>
                <span v-else></span>
                <el-button @click="cancelCloudCert(scope.row.orderNo)" type="text" v-if="scope.row.status<=2">取消</el-button>
                <span v-else></span>
                <el-button @click="getCloudInfo(scope.row.orderNo)" type="text">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pager-list">
            <v-pagination :size="pageSize" :sum="Number(total)" @page="pageChange" @current="currentChange" :currentPage="pageIndex" />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { cloudStatus, productNorms } from '@/utils/options'
import { productSpecs, productType, orderStatus } from '@/utils/typeMatch.js'
import pagination from '@/components/pagination.vue'
export default {
  data() {
    return {
      activeName: 'api',
      cloudLoad: false,
      cloudlist: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      productDescription: [],
      cloudStatus: cloudStatus,
      productNorms: productNorms,
      options: [],
      productSpecs: 1
    }
  },
  components: {
    'v-pagination': pagination
  },
  filters: {
    productSpecs,
    productType,
    orderStatus
  },
  methods: {
    handleClick(tab) {
      this.pageIndex = 1
      this.pageSize = 10
      this.options = {}
      this.clearFilter()
      if (tab.name === 'api') {
        this.productSpecs = 1
      } else {
        this.productSpecs = 0
      }
      this.getCloud()
    },
    clearFilter() {
      this.$refs.tableList1.clearFilter()
      this.$refs.tableList2.clearFilter()
    },
    filterTag(value) {
      for (let key in value) {
        if (this.options[key] && this.options[key].length === 0) {
          this.options[key] = ''
        } else {
          this.options[key] = value[key].join(',')
        }
      }
      this.pageIndex = 1
      this.getCloud()
    },
    getCloud() {
      this.cloudLoad = true
      let data = {
        productType: 1,
        productSpecs: this.productSpecs,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
      for (let key in this.options) {
        data[key] = this.options[key]
      }
      this.$api
        .getOrderList(data)
        .then(res => {
          this.cloudLoad = false
          this.total = res.data.total || 0
          this.cloudlist = res.data.records
        })
        .catch(() => {
          this.cloudLoad = false
        })
    },
    pageChange(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getCloud()
    },
    currentChange(val) {
      this.pageIndex = val
      this.getCloud()
    },
    getCloudInfo(transactionId) {
      this.$parent.openCloudDetails(transactionId)
    },
    goPayment(orderNo) {
      this.$store.commit('user/setOrderNo', orderNo)
      this.$router.push({
        name: 'cloudCertificate',
        query: { type: 1, specs: this.productSpecs }
      })
    },
    cancelCloudCert(orderNo) {
      this.$confirm(`确定取消申请？`, '提示', {
        confirmButtonText: '确定',
        showCancelButton: '取消',
        type: 'warning'
      })
        .then(() => {
          this.cancelOrder(orderNo)
        })
        .catch(() => {})
    },
    cancelOrder(orderNo) {
      this.detailsLoad = true
      this.$api
        .cancelOrder({ orderNo: orderNo })
        .then(res => {
          this.detailsLoad = false
          if (res.code === '0') {
            this.$message.success(res.msg)
            this.getCloud()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch(() => {
          this.detailsLoad = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.cloudCert {
  ::v-deep .el-tabs--card > .el-tabs__header {
    border: none;
  }
  ::v-deep .el-tabs__nav {
    border: none;
  }
  ::v-deep .el-tabs__item {
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    border-radius: 2px;
    border: 1px solid #dee0e9;
    margin-right: 24px;
  }
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
    border-left: 1px solid #dee0e9;
  }
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    color: #1c67f3;
    background: #f1f5ff;
    border-radius: 2px;
    border: none;
  }
}
.signatureList {
  padding-bottom: 72px;
  ::v-deep .el-table tr > th {
    background: #f7faff;
    padding: 2px 0;
    color: #6c737b;
    font-weight: normal;
    border-top: 1px solid #dee0e9;
    border-color: #dee0e9;
  }
  ::v-deep .el-table td {
    padding: 8px 0;
    border-color: #dee0e9;
  }
  ::v-deep .el-icon-arrow-down {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background: url('~@/assets/image/personCenter/select.png') no-repeat center;
    background-size: 16px;
    cursor: pointer;
  }
  ::v-deep .el-table th > .cell.highlight {
    color: $primaryColor;
  }
  .statusColor {
    height: 24px;
    line-height: 22px;
    display: inline-block;
    padding: 0 8px;
    border-radius: 15px;
    &.statusColor1,
    &.statusColor2 {
      color: #e89e1c;
      background: #fef8ec;
      border: 1px solid #fbbc4d;
    }
    &.statusColor3,
    &.statusColor4 {
      color: #52c41a;
      background: #eff9eb;
      border: 1px solid #d4f0c8;
    }
    &.statusColor5 {
      color: #e34d59;
      background: #ffeeee;
      border: 1px solid #f7caca;
    }
    &.statusColor6 {
      color: #707276;
      background: #ffffff;
      border: 1px solid #dbdce0;
    }
  }
}
.pager-list {
  padding-top: 12px;
}
</style>