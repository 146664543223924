<template>
  <div class="userSideBar">
    <div v-for="(item,index) in sideBarList" :key="index" :class="['userSideTitle',{active: currentIndex === index}]" @click="changeActive(index)">
      <svg-icon :icon-class="item.icon" />
      {{item.name}}
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentIndex: 0,
    }
  },
  props: ['sideBarList'],
  methods: {
    changeActive (i) {
      this.currentIndex = i
      this.$emit('changeMenu', i)
    },
  },
}
</script>

<style lang="scss" scoped>
.userSideBar {
  width: 220px;
  height: 100%;
  background: #fcfdfd;
  border-right: 1px solid #dee0e9;
  padding: 16px 0;
  line-height: 50px;
}
.userSideTitle {
  height: 48px;
  box-sizing: border-box;
  font-size: 14px;
  color: #303b50;
  padding-left: 19px;
  cursor: pointer;
}
.svg-icon {
  font-size: 16px;
  margin-right: 8px;
  color: #9fa3ab;
  vertical-align: -0.2em;
}
.active {
  color: $primaryColor;
  background: linear-gradient(90deg, #e4edff 0%, #fcfdfd 100%);
  .svg-icon {
    color: $primaryColor;
  }
}
</style>