<template>
  <div class="orderList" v-loading="orderLoad">
    <el-table ref="tableList" :data="orderlist" style="width: 100%;" header-row-class-name="tableHeadTitle" @filter-change="filterTag" :filter-multiple='false'>
      <el-table-column prop="orderNo" label="订单号" min-width="195"></el-table-column>
      <el-table-column prop="productType" label="产品名称" min-width="180">
        <template slot-scope="scope">
          <span>{{scope.row.productType|productType}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="productName" label="类型" column-key="productCode" :filters="productDescription" :filter-multiple='false' min-width="140"></el-table-column>
      <el-table-column prop="companyName" label="集成企业" min-width="180" show-overflow-tooltip></el-table-column>
      <el-table-column prop="orderType" label="业务类型" column-key="orderType" :filters="buyType" :filter-multiple='false' min-width="100">
        <template slot-scope="scope">
          <span>{{scope.row.orderType|orderType}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="productSpecs" label="规格" min-width="80">
        <template slot-scope="scope">
          <span>{{scope.row.productSpecs===0?'纯API版':'网页版'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="times" label="次数" min-width="80"></el-table-column>
      <el-table-column prop="status" label="状态" column-key="orderStatus" :filters="productStatus" :filter-multiple='false' min-width="90">
        <template slot-scope="scope">
          <span :class="'statusColor statusColor'+scope.row.status">{{scope.row.status|orderStatus}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="订单金额" min-width="100">
        <template slot-scope="scope">
          <span>{{'¥'+scope.row.price||'- -'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="orderTime" label="下单时间" min-width="160"></el-table-column>
      <el-table-column prop="" fixed="right" label="操作" min-width="160">
        <template slot-scope="scope">
          <el-button type="text" v-if="scope.row.status===2" @click="goPayment(scope.row.orderNo)">立即付款</el-button>
          <span v-else></span>
          <el-button @click="cancelCloudCert(scope.row.orderNo)" type="text" v-if="scope.row.status===2">取消</el-button>
          <span v-else></span>
          <el-button @click="getOrderInfo(scope.row.orderNo)" type="text">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-list">
      <v-pagination :size="pageSize" :sum="Number(total)" @page="pageChange" @current="currentChange" :currentPage="pageIndex" />
    </div>
  </div>
</template>

<script>
import pagination from '@/components/pagination.vue'
import { productStatus, productNorms, buyType } from '@/utils/options'
import {
  productSpecs,
  productType,
  orderStatus,
  orderType
} from '@/utils/typeMatch.js'
export default {
  data() {
    return {
      orderLoad: false,
      orderlist: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      productDescription: [],
      productStatus: productStatus,
      productNorms: productNorms,
      buyType: buyType,
      options: []
    }
  },
  filters: {
    productSpecs,
    productType,
    orderStatus,
    orderType
  },
  components: {
    'v-pagination': pagination
  },
  mounted() {
    this.getProductCodeList()
  },
  methods: {
    getProductCodeList() {
      this.orderLoad = true
      this.$api
        .getCodeList({ productType: 0 })
        .then(res => {
          this.orderLoad = false
          this.productDescription = res.data
        })
        .catch(() => {
          this.orderLoad = false
        })
    },
    filterTag(value) {
      for (let key in value) {
        if (this.options[key] && this.options[key].length === 0) {
          this.options[key] = ''
        } else {
          this.options[key] = value[key].join(',')
        }
      }
      this.pageIndex = 1
      this.getOrders()
    },
    getOrders() {
      this.orderLoad = true
      let data = {
        productType: 0,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
      for (let key in this.options) {
        data[key] = this.options[key]
      }
      this.$api
        .getOrderList(data)
        .then(res => {
          this.orderLoad = false
          this.total = res.data.total || 0
          this.orderlist = res.data.records
        })
        .catch(() => {
          this.orderLoad = false
        })
    },
    pageChange(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getOrders()
    },
    currentChange(val) {
      this.pageIndex = val
      this.getOrders()
    },
    getOrderInfo(order) {
      this.$parent.openOrderDetails(order)
    },
    cancelCloudCert(orderNo) {
      this.$confirm(`确定取消申请？`, '提示', {
        confirmButtonText: '确定',
        showCancelButton: '取消',
        type: 'warning'
      })
        .then(() => {
          this.cancelOrder(orderNo)
        })
        .catch(() => {})
    },
    cancelOrder(orderNo) {
      this.detailsLoad = true
      this.$api
        .cancelOrder({ orderNo: orderNo })
        .then(res => {
          this.detailsLoad = false
          if (res.code === '0') {
            this.$message.success(res.msg)
            this.getOrders()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch(() => {
          this.detailsLoad = false
        })
    },
    goPayment(orderNo) {
      this.$store.commit('user/seAppId', '')
      this.$store.commit('user/setOrderNo', orderNo)
      this.$router.push({ name: 'identityAuth' })
    }
  }
}
</script>

<style lang="scss" scoped>
.orderList {
  padding-bottom: 72px;
  ::v-deep .el-table tr > th {
    background: #f7faff;
    padding: 2px 0;
    color: #6c737b;
    font-weight: normal;
    border-top: 1px solid #dee0e9;
    border-color: #dee0e9;
  }
  ::v-deep .el-table td {
    padding: 8px 0;
    border-color: #dee0e9;
  }
  ::v-deep .el-icon-arrow-down {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background: url('~@/assets/image/personCenter/select.png') no-repeat center;
    background-size: 16px;
    cursor: pointer;
  }
  ::v-deep .el-table th > .cell.highlight {
    color: $primaryColor;
  }
  .statusColor {
    height: 24px;
    line-height: 22px;
    display: inline-block;
    padding: 0 8px;
    border-radius: 15px;
    &.statusColor1,
    &.statusColor2 {
      color: #e89e1c;
      background: #fef8ec;
      border: 1px solid #fbbc4d;
    }
    &.statusColor3,
    &.statusColor4 {
      color: #52c41a;
      background: #eff9eb;
      border: 1px solid #d4f0c8;
    }
    &.statusColor5 {
      color: #e34d59;
      background: #ffeeee;
      border: 1px solid #f7caca;
    }
    &.statusColor6 {
      color: #707276;
      background: #ffffff;
      border: 1px solid #dbdce0;
    }
  }
}
.pager-list {
  padding-top: 12px;
}
</style>