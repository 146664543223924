<template>
  <div>
    <div class="transactionList">
      <el-table ref="tableList1" :data="transaction_list" style="width: 100%;" header-row-class-name="tableHeadTitle" v-loading="businessLoad" @filter-change="filterTag" :filter-multiple='false'>
        <el-table-column prop="transactionNo" label="业务单号" min-width="210" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createdDate" label="申请时间" min-width="160" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="业务单名称" min-width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="type" label="业务类型" min-width="100" :filters="transTypeOption" :column-key="'businessType'" :filter-multiple='false'>
          <template slot-scope="scope">
            <span>
              {{scope.row.type |transactionTypeFilter}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="业务单状态" min-width="110" column-key="status" :filters="transStatusOption" :filter-multiple='false'>
          <template slot-scope="scope">
            <span :class="'statusColor statusColor' + scope.row.status">{{scope.row.status | certTransStatusFilter}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" prop="status" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="getOpenPage({name: businessType[scope.row.type],transId: scope.row.id})" type="text" v-show="scope.row.status<11||scope.row.status===14">继续办理</el-button>
            <el-button @click="getBusinessInfo(scope.row.id)" type="text">详情</el-button>
            <el-button @click="rewriteUkey(scope.row.id)" type="text" v-if="(scope.row.type===2||scope.row.type===3)&&scope.row.status===11">重新写入</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pager-list">
      <v-pagination :size="pageSize" :sum="Number(total)" @page="pageChange" @current="currentChange" :currentPage="pageIndex" />
    </div>
    <v-detail ref="detailInfo" @getlistData="getDataList"></v-detail>
    <v-invoiveImgShow ref="invoiveImgShow"></v-invoiveImgShow>
    <v-mailNews ref="mailNews"></v-mailNews>
    <UkeyLogin v-model="ukeyLoginShow" @logined="ukeyLogined" :Ukey="Ukey" :certSn="Ukey.certSn" />
    <div class="processTips" v-if="renewaling">
      <div class="process">
        <div class="icon">
          <img src="@/assets/icons/service/loading-icon.png">
        </div>
        <div class="tt">证书{{transaction_list.type===3?'更新':'变更'}}中</div>
        <div class="hint">提示：请不要关闭浏览器，不要拔出Ukey，在此界面等待{{transaction_list.type===3?'更新':'变更'}}完毕，以免造成证书{{transaction_list.type===3?'更新':'变更'}}失败</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Ukey } from './ukey'
import mailNews from '@/components/mailNews'
import invoiveImgShow from './invoiveImgShow'
import pagination from '@/components/pagination.vue'
import detail from './../serviceCenter/transactionDetail.vue'
import UkeyLogin from './../../service/components/ukeyLogin.vue'
import { transTypeOption, transStatusOption } from '@/utils/options'
import { certTransStatus, transactionType } from '@/utils/typeMatch.js'
export default {
  data() {
    return {
      transaction_list: [],
      businessLoad: false,
      transTypeOption: transTypeOption,
      transStatusOption: transStatusOption,
      ukeyLoginShow: false,
      renewaling: false,
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      pager: 0,
      options: {},
      businessType: {
        1: 'certApply',
        2: 'certChange',
        3: 'certRenewal',
        4: 'certCancel',
        7: 'certUnlock',
        9: 'certReissue'
      }
    }
  },
  components: {
    'v-detail': detail,
    'v-invoiveImgShow': invoiveImgShow,
    'v-mailNews': mailNews,
    UkeyLogin,
    'v-pagination': pagination
  },
  filters: {
    certTransStatusFilter: certTransStatus,
    transactionTypeFilter: transactionType
  },
  created() {
    this.Ukey = new Ukey(this)
  },
  methods: {
    getDataList() {
      this.businessLoad = true
      let data = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
      for (let key in this.options) {
        data[key] = this.options[key]
      }
      this.$api.getTransactionListPage(data).then(res => {
        this.businessLoad = false
        this.transaction_list = res.data.records
        this.total = res.data.total
        this.pager = res.data.pages
      })
    },
    pageChange(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    currentChange(val) {
      this.pageIndex = val
      this.getDataList()
    },
    filterTag(value) {
      for (let key in value) {
        if (this.options[key] && this.options[key].length === 0) {
          this.options[key] = ''
        } else {
          this.options[key] = value[key].join(',')
        }
      }
      this.pageIndex = 1
      this.getDataList()
    },
    getOpenPage(item) {
      let newPage = this.$router.resolve({
        name: item.name,
        query: { transId: item.transId }
      })
      window.open(newPage.href, '_blank')
    },
    getInvoiceQrCode(transId) {
      this.$refs.invoiveImgShow.getShow(transId)
    },
    getBusinessInfo(item) {
      this.$refs.detailInfo.getDetail(item)
    },
    ukeyLogined() {},
    rewriteUkey(id) {
      this.transactionId = id
      this.Ukey.connect()
        .then(() => {
          this.ukeyLogined = () => {
            this.renewaling = true
            this.getRemakeCert()
          }
          this.ukeyLoginShow = true
        })
        .catch(err => {
          if (err.message) this.$message.error(err.message)
        })
    },
    getRemakeCert() {
      this.renewaling = true
      this.$api
        .getRemakeCert({
          transId: this.transactionId,
          certSn: this.Ukey.certSn
        })
        .then(res => {
          this.certData = res.data
          this.Ukey.writeCert(this.certData)
            .then(res => {
              this.renewaling = false
              if (res) {
                this.$message({
                  type: 'success',
                  message: '写入成功！',
                  onClose: () => {}
                })
              } else {
                if (res !== undefined) this.$message.error(res.message)
              }
            })
            .catch(err => {
              this.renewaling = false
              this.$message.error(err.message)
            })
        })
        .catch(() => {
          this.renewaling = false
        })
    },
    getmailNew(transId) {
      this.$api
        .getlogisticsByTransId({
          transId: transId
        })
        .then(data => {
          if (data.data && data.data.expressNo) {
            this.$refs.mailNews.getmailNewData(data.data.expressNo)
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.transactionList {
  ::v-deep .el-table tr > th {
    background: #f7faff;
    padding: 2px 0;
    color: #6c737b;
    font-weight: normal;
    border-top: 1px solid #dee0e9;
    border-color: #dee0e9;
  }
  ::v-deep .el-table td {
    padding: 8px 0;
    border-color: #dee0e9;
  }
  ::v-deep .el-icon-arrow-down {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background: url('~@/assets/image/personCenter/select.png') no-repeat center;
    background-size: 16px;
    cursor: pointer;
  }
  .statusColor {
    height: 24px;
    line-height: 22px;
    display: inline-block;
    padding: 0 8px;
    color: #e89e1c;
    background: #fef8ec;
    border-radius: 15px;
    border: 1px solid #fbbc4d;
    &.statusColor2 {
      color: #1c67f3;
      background: #dbe4ff;
      border: 1px solid #a9beff;
    }
    &.statusColor11 {
      color: #52c41a;
      background: #eff9eb;
      border: 1px solid #d4f0c8;
    }
    &.statusColor3,
    &.statusColor13 {
      color: #e34d59;
      background: #ffeeee;
      border: 1px solid #f7caca;
    }
    &.statusColor12 {
      color: #707276;
      background: #ffffff;
      border: 1px solid #dbdce0;
    }
  }
  .el-button {
    margin-right: 10px;
  }
  .el-button + .el-button {
    margin: 0 10px 0 0;
  }
}
.pager-list {
  padding-top: 12px;
}
.processTips {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  .process {
    width: 1300px;
    height: 718px;
    padding-top: 140px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .icon {
    width: 52px;
    height: 52px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px #e4e5e6;
    margin: 0 auto;
    border-radius: 50%;
    padding: 5px;
    img {
      width: 42px;
      height: 42px;
      animation: loading 0.6s linear infinite;
    }
  }
  .tt {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    margin: 24px auto;
  }
  .hint {
    width: 828px;
    background: #f7faff;
    border-radius: 2px;
    line-height: 94px;
    font-size: 16px;
    color: #3a6afe;
    margin: 0 auto;
  }
}
</style>