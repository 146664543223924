<template>
  <div class="reportlist" v-loading="reportLoad">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="电子版报告" name="electron">
        <div class="signatureList">
          <el-table ref="tableList1" :data="reportlist" style="width: 100%;" header-row-class-name="tableHeadTitle" @filter-change="filterTag" :filter-multiple='false'>
            <el-table-column prop="transactionNo" label="业务单号" min-width="210"></el-table-column>
            <el-table-column prop="createdDate" label="申请时间" min-width="160"></el-table-column>
            <el-table-column prop="transactionName" label="业务单名称" show-overflow-tooltip min-width="180"></el-table-column>
            <el-table-column prop="transactionType" label="业务类型" min-width="100">
              <template slot-scope="scope">
                <span>{{scope.row.transactionType===0?'电子版':'纸质版'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="金额（元）" min-width="120">
              <template slot-scope="scope">
                <span>{{'¥'+scope.row.amount||'- -'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="transactionStatus" label="业务单状态" column-key="transactionStatus" :filters="reportElectronStatus" :filter-multiple='false' min-width="110">
              <template slot-scope="scope">
                <span :class="'statusColor statusColor'+scope.row.transactionStatus">{{scope.row.transactionStatus|reportStatus}}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" prop="" label="操作" width="170">
              <template slot-scope="scope">
                <el-button type="text" v-if="scope.row.transactionStatus===1" @click="goPayment(scope.row.transactionId)">立即付款</el-button>
                <span v-else></span>
                <el-button type="text" v-if="scope.row.transactionStatus===1" @click="cancelReport(scope.row.transactionId)">取消</el-button>
                <el-button type="text" v-else-if="scope.row.transactionType===0&&scope.row.transactionStatus===7" @click="downloadPdf(scope.row.reportUrl)">下载报告</el-button>
                <el-button @click="getOrderInfo(scope.row.transactionId)" type="text">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pager-list">
            <v-pagination :size="pageSize" :sum="Number(total)" @page="pageChange" @current="currentChange" :currentPage="pageIndex" />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="纸质版报告" name="paper">
        <div class="signatureList">
          <el-table ref="tableList2" :data="reportlist" style="width: 100%;" header-row-class-name="tableHeadTitle" @filter-change="filterTag" :filter-multiple='false'>
            <el-table-column prop="transactionNo" label="业务单号" min-width="210"></el-table-column>
            <el-table-column prop="createdDate" label="申请时间" min-width="160"></el-table-column>
            <el-table-column prop="transactionName" label="业务单名称" show-overflow-tooltip min-width="180"></el-table-column>
            <el-table-column prop="transactionType" label="业务类型" min-width="80">
              <template slot-scope="scope">
                <span>{{scope.row.transactionType===0?'电子版':'纸质版'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="金额（元）" min-width="110">
              <template slot-scope="scope">
                <span>{{'¥'+scope.row.amount||'- -'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="transactionStatus" label="业务单状态" column-key="transactionStatus" :filters="reportPaperStatus" :filter-multiple='false' min-width="120">
              <template slot-scope="scope">
                <span :class="'statusColor statusColor'+scope.row.transactionStatus">{{scope.row.transactionStatus|reportStatus}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" width="170">
              <template slot-scope="scope">
                <el-button type="text" v-if="scope.row.transactionStatus===1" @click="goPayment(scope.row.transactionId)">立即付款</el-button>
                <span v-else></span>
                <el-button type="text" v-if="scope.row.transactionStatus===1" @click="cancelReport(scope.row.transactionId)">取消</el-button>
                <span v-else></span>
                <el-button @click="getOrderInfo(scope.row.transactionId)" type="text">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pager-list">
            <v-pagination :size="pageSize" :sum="Number(total)" @page="pageChange" @current="currentChange" :currentPage="pageIndex" />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { reportStatus } from '@/utils/typeMatch'
import { reportPaperStatus, reportElectronStatus } from '@/utils/options'
import pagination from '@/components/pagination.vue'
export default {
  data() {
    return {
      activeName: 'electron',
      reportLoad: false,
      reportlist: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      productDescription: [],
      reportPaperStatus: reportPaperStatus,
      reportElectronStatus: reportElectronStatus,
      options: [],
      transactionType: 0
    }
  },
  components: {
    'v-pagination': pagination
  },
  filters: {
    reportStatus
  },
  methods: {
    handleClick(tab) {
      this.pageIndex = 1
      this.pageSize = 10
      this.options = {}
      this.clearFilter()
      if (tab.name === 'electron') {
        this.transactionType = 0
      } else {
        this.transactionType = 1
      }
      this.getReportList()
    },
    clearFilter() {
      this.$refs.tableList1.clearFilter()
      this.$refs.tableList2.clearFilter()
    },
    filterTag(value) {
      for (let key in value) {
        if (this.options[key] && this.options[key].length === 0) {
          this.options[key] = ''
        } else {
          this.options[key] = value[key].join(',')
        }
      }
      this.pageIndex = 1
      this.getReportList()
    },
    getReportList() {
      this.reportLoad = true
      let data = {
        transactionType: this.transactionType,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
      for (let key in this.options) {
        data[key] = this.options[key]
      }
      this.$api
        .getReportPage(data)
        .then(res => {
          this.reportLoad = false
          if (res.code === '0') {
            this.total = res.data.total || 0
            this.reportlist = res.data.records
          }
        })
        .catch(() => {
          this.reportLoad = false
        })
    },
    pageChange(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getReportList()
    },
    currentChange(val) {
      this.pageIndex = val
      this.getReportList()
    },
    getOrderInfo(transactionId) {
      this.$parent.openReportDetails(transactionId)
    },
    cancelReport(transactionId) {
      this.$confirm('确认取消该业务单吗？', '取消确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.reportLoad = true
        this.$api
          .reportCancel({ transactionId: transactionId })
          .then(res => {
            this.reportLoad = false
            if (res.code === '0') {
              this.$message.success('取消成功')
              this.getReportList()
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch(() => {
            this.reportLoad = false
          })
      })
    },
    goPayment(transactionId) {
      this.$router.push({
        name: 'verifyReport',
        query: { transactionId: transactionId }
      })
    },
    downloadPdf(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.reportlist {
  ::v-deep .el-tabs--card > .el-tabs__header {
    border: none;
  }
  ::v-deep .el-tabs__nav {
    border: none;
  }
  ::v-deep .el-tabs__item {
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    border-radius: 2px;
    border: 1px solid #dee0e9;
    margin-right: 24px;
  }
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
    border-left: 1px solid #dee0e9;
  }
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    color: #1c67f3;
    background: #f1f5ff;
    border-radius: 2px;
    border: none;
  }
}
.signatureList {
  padding-bottom: 72px;
  ::v-deep .el-table tr > th {
    background: #f7faff;
    padding: 2px 0;
    color: #6c737b;
    font-weight: normal;
    border-top: 1px solid #dee0e9;
    border-color: #dee0e9;
  }
  ::v-deep .el-table td {
    padding: 8px 0;
    border-color: #dee0e9;
  }
  ::v-deep .el-icon-arrow-down {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background: url('~@/assets/image/personCenter/select.png') no-repeat center;
    background-size: 16px;
    cursor: pointer;
  }
  ::v-deep .el-table th > .cell.highlight {
    color: $primaryColor;
  }
  .statusColor {
    height: 24px;
    line-height: 22px;
    display: inline-block;
    padding: 0 8px;
    border-radius: 15px;
    &.statusColor1,
    &.statusColor2,
    &.statusColor5 {
      color: #e89e1c;
      background: #fef8ec;
      border: 1px solid #fbbc4d;
    }
    &.statusColor0,
    &.statusColor3,
    &.statusColor4,
    &.statusColor6 {
      color: #1c67f3;
      background: #dbe4ff;
      border: 1px solid #a9beff;
    }
    &.statusColor-1 {
      color: #707276;
      background: #ffffff;
      border: 1px solid #dbdce0;
    }
    &.statusColor7 {
      color: #52c41a;
      background: #eff9eb;
      border: 1px solid #d4f0c8;
    }
  }
}
.pager-list {
  padding-top: 12px;
}
</style>